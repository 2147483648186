



































































































































































































































































































































































































.statistics{
  margin: 5px 15px 0;
  span+span{
    margin-left: 15px;
  }
}
